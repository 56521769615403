html {
  height: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

body {
  height: inherit;
  margin: 0;
}

#app {
  height: inherit;
}

.icon-group {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.no-text-wrap {
  text-wrap: nowrap !important;
  margin-left: 2px !important;
}

@media (width <= 1400px) {
  #reinke-main-page-title {
    display: none;
  }
}

.number-input input {
  border-bottom: 1px solid #0000;
  max-width: 70px;
}

.number-input .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 0 !important;
}

.sprinkler-results-table {
  overflow-x: initial !important;
}

.sprinkler-results-table table {
  position: relative;
}

.sprinkler-results-table tr {
  height: 10px;
}

.sprinkler-results-table tr td {
  padding: 0;
  height: auto !important;
}

.sprinkler-results-table tr th {
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: auto !important;
}

.span-irrigation-table td, .span-irrigation-table th {
  padding-right: 20px;
}

.printable-page .mapboxgl-ctrl-logo, .printable-page .mapboxgl-ctrl-icon, .printable-page .mapboxgl-ctrl-attrib {
  display: none !important;
}

.coin {
  animation: 5s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(720deg);
  }
}

.tab_highlighted {
  color: #1976d2 !important;
}
/*# sourceMappingURL=index.ff4e5b3f.css.map */
