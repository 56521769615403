html {
    height: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

body {
    height:inherit;
    margin: 0px;
}

#app {
    height: inherit;
}

.icon-group{
    display: flex;
    flex-direction: row;
    align-items: center;  
}

.no-text-wrap{
    text-wrap: nowrap !important;
    margin-left: 2px !important;
}

@media (max-width: 1400px) {
    #reinke-main-page-title{
        display: none;
    } 
}

.number-input input{
    max-width: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.number-input .css-v4u5dn-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 0px !important;
}

.sprinkler-results-table{  
    overflow-x: initial !important;
}

.sprinkler-results-table table{  
    position: relative;
}

.sprinkler-results-table tr{  
    height: 10px;
}

.sprinkler-results-table tr td{  
    height: auto !important;
    padding: 0px;
}

.sprinkler-results-table tr th{  
    height: auto !important;
    padding: 0px;
    position: sticky;
    top: 0;
}

.span-irrigation-table td, .span-irrigation-table th{
    padding-right: 20px;

}

.printable-page .mapboxgl-ctrl-logo {
    /*added because the elevation table has a hidden "map" element and this leaves a hanging map box logo*/
    display: none !important;
}

.printable-page .mapboxgl-ctrl-icon {
    display: none !important;
}

.printable-page .mapboxgl-ctrl-attrib {
    display: none !important;
}

.coin {
    animation: spin 5s linear infinite;
  }

  @keyframes spin {
    0%   { transform: rotateY(0deg); }
    100% { transform: rotateY(720deg); }
}

.tab_highlighted {
    color: #1976d2 !important;
}